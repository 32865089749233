import React from "react";
import { Link } from "react-router-dom";

import { scrollToTop } from "../utils/Utils";

import "./ArticleCard.css";

function ArticleCard(props) {

    // used for state in the article view page
    const article = {
        id: props.id,
        title: props.title,
        subtitle: props.subtitle,
        content: props.content
    };

    return (
        <Link to={"/articles/" + props.articleId} className="card-container" onClick={scrollToTop} state={article}>
            <h1 className="card-title">{props.title}</h1>
            <p className="card-subtitle">{props.subtitle}</p>
        </Link>
    );
}

export default ArticleCard;
