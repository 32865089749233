import React from 'react'

import './Footer.css'

import logo from '../assets/images/right-facing-logo.svg'

function Footer() {
  return (
    <footer className='footer-container'>
        <p>Designed and developed by Cool Panda Software: <a href="https://www.coolxpanda.com/">coolxpanda.com</a></p>
        <p className='image-container'><a href="https://www.coolxpanda.com/"><img alt="Cool Panda Software logo" src={logo}/></a></p>
        <p>coolpandasoftware@gmail.com</p>
    </footer>
  )
}

export default Footer