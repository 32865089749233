import React from "react";
import { useLocation } from "react-router-dom";

import "./Article.css";

function Article() {
    // get the article that was selected from the ArticleCard component
    const location = useLocation();
    const article = location.state;

    return (
        <div className="article-container">
            <h1 className="article-title">{article.title}</h1>
            <h2 className="article-subtitle">{article.subtitle}</h2>
            <p className="article-text">{article.content}</p>
        </div>
    );
}

export default Article;
