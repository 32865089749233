import React, { useEffect, useState } from "react";

import { supabase } from "../utils/SupabaseClient";

import "./Home.css";

import ArticleCard from "../components/ArticleCard";

import teamPicture from "../assets/images/team-picture.jpg";

function Home() {
    // holds all the articles of news for the website
    const [articles, setArticles] = useState([]);

    // uses the supabase client to get the articles from the database
    async function getArticles() {
        try {
            let { data: posts } = await supabase.from("news").select("*");

            setArticles(posts);
        } catch (err) {
            console.log("Error getting articles:");
        }
    }

    // get the articles on the first render and ignore warning of empty array since this is intended behavior
    useEffect(() => {
        getArticles();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="home-container">
            <p className="team-picture">
                <img src={teamPicture} alt="MSU Judo Team" />
            </p>
            <h1 className="welcome-title">Welcome to MSU Judo Club!</h1>
            <p className="welcome-text">
                Welcome to MSU Judo Club! During the school year we meet every Monday, Wednesday, and
                Friday from 7:00 - 9:00 pm (see the calendar below for the practice schedule). To join, please click "Join Team" at{" "}
                <a href="https://imleagues.com/spa/team/31c9c59fee854941a14e448d97faa56c/home#teamname">
                    IMLeagues
                </a>{" "}
                and agree to the waiver, then come join us on the mats!
            </p>

            <section className="section-container">
                <h2 className="section-title">Club Schedule</h2>
                <iframe
                    src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FDetroit&showTabs=1&title=Judo%20Club%20Schedule&src=bXN1Y2x1Ymp1ZG9AZ21haWwuY29t&color=%23039BE5"
                    className="calendar"
                    title="schedule-calendar"
                ></iframe>
            </section>

            <section className="section-container">
                <h2 className="section-title">Team News</h2>
                <p className="section-text">
                    Check out some of our tournament results and other team news
                    here!
                </p>
                {articles.map((post) => (
                    <ArticleCard
                        key={post.created_at}
                        articleId={post.id}
                        title={post.title}
                        subtitle={post.subtitle}
                        content={post.content}
                    />
                ))}
            </section>
        </div>
    );
}

export default Home;
