import React from 'react'
import {Link} from 'react-router-dom'

import { scrollToTop } from '../utils/Utils';

import './Header.css'

function Header() {
  return (
    <header className='header-container'>
        <div className='header-section-wrapper'>
            <Link to="/" className='header-title' onClick={scrollToTop}>MSU Judo Club</Link>

        </div>

        <nav className='header-section-wrapper'>
            <Link to="/" className='nav-item' onClick={scrollToTop}>Home</Link>
            <Link to="/about" className='nav-item' onClick={scrollToTop}>About</Link>
            <Link to="/contact" className='nav-item' onClick={scrollToTop}>Contact</Link>
        </nav>
    </header>
  )
}

export default Header