import { Routes, Route } from "react-router-dom";

import './App.css';

import Home from './pages/Home';
import About from './pages/About';
import Contact from "./pages/Contact";
import Article from "./pages/Article";

import Header from './components/Header';
import Footer from './components/Footer';

function App() {

  return (
    <div>
      <Header />
      <div className="page-content">
      <div className='header-buffer'></div>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/articles/:id" element={<Article />}/>
          </Routes>
      </div>
    
      <Footer />
    </div>
  );
}

export default App;
