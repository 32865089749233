import React, { useEffect, useState } from "react";

import { supabase } from "../utils/SupabaseClient";

import "./Contact.css";

function Contact() {
    // holds all the eboard info
    const [eboard, setEboard] = useState([]);

    // uses the supabase client to get the eboard from the database
    async function getEboard() {
        try {
            let { data: members } = await supabase.from("eboard").select("*");

            setEboard(members);
        } catch (err) {
            console.log("Error getting eboard");
        }
    }

    // get the eboard on the first render and ignore warning of empty array since this is intended behavior
    useEffect(() => {
        getEboard();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="contact-container">
            <h1 className="contact-title">Contact</h1>
            <p>Please reach out to us with any questions you may have</p>
            <table className="contact-table">
                <tbody>
                    {eboard.map((member) => (
                        <tr className="contact-table-row" key={member.id}>
                            <td className="contact-table-item">
                                {member.name}
                            </td>
                            <td className="contact-table-item">
                                {member.role}
                            </td>
                            <td className="contact-table-item">
                                {member.email}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Contact;
