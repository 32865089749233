import React from "react";

import "./About.css";

import usjfLogo from "../assets/images/2021-USJFLogo.png";
import belts from "../assets/images/belts.png";

function About() {
    return (
        <div className="about-page-container">
            <h1 className="about-title">About</h1>
            <p className="about-text">
                MSU Judo Club is a recognized judo club under the USJF. Judo, or
                "the gentle way", is a martial art where the goal is to throw an
                opponent onto their back, or pin/ submit them on the ground. We
                hold practices 3 times a week on Monday, Wednesdays, and
                Fridays. Students who join the club also have a chance to rank
                up and earn belts to show off their mastery of the skills they
                have learned. For those students who are interested, there are
                also have competitions that they can attend. We are always
                looking for new students to join us and we'd love to have you as
                well!
            </p>
            <iframe width="350" height="197" src="https://www.youtube.com/embed/Jyuog4DOysU?si=Fh7mLsCA7SqlRcaI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen=""></iframe>
            <p className="about-text">More info on the USJF and belts below</p>
            <section className="usjf-belts-section">
              <p className="about-img-container"><a href="https://www.usjf.com/"><img src={usjfLogo} alt="USJF Logo" />
                    </a></p>
              <p className="about-img-container"><a href="https://en.wikipedia.org/wiki/Rank_in_judo"><img src={belts} alt="Judo belt rankings" /></a></p>
            </section>
        </div>
    );
}

export default About;
